import { Badge, Box, Button, Card, Divider, Grid, IconButton, Popover, Skeleton, Stack, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Drawer from "../components/drawer";
import { Context as AppContext } from "../context/AppContext";
import BreadcrumbTrail from "../components/BreadcrumbsTrail";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import { ChargingStation, Launch, ModeEdit, Receipt, Notifications } from "@mui/icons-material";
import { getCustomerDetails } from "../Api/homeApi";
import CreditCard from "../components/CreditCard";
import theme from "../theme";
import moment from "moment";
import { capitalize } from "../utils/utilities";
import { DeviceList, InvoiceList } from "../components/ProfileTabs";
import { NameAvatar } from "../components/muiComponents";
import { fetchUnreadNotifications } from "../utils/firestore";

const textFieldProps = {
    // style: { fontWeight: "bold", color: "GrayText" }
}

export const sortNoti = (a, b) => {
    if (!a.sentTime || !b.sentTime) {
        return 0
    }
    if (a.sentTime < b.sentTime) {
        return 1
    }
    if (a.sentTime > b.sentTime) {
        return -1
    }
    return 0
}

export default () => {
    const navigate = useNavigate()
    const [containerClass, setContainerClass] = useState("");
    const [drawerContainerClass, setDrawerContainerClass] = useState("");
    const location = useLocation()
    const { state, show } = useContext(AppContext);
    const [tab, setTab] = useState("devices")
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        user: {},
        card: {},
        subscription: {},
        plan: {},
        invoices: [],
        devices: []
    })
    const [editMode, setEditMode] = useState(null)
    const [notifications, setNotifications] = useState([])
    const classes = useStyles({ editMode })
    let TNC_URL = data.user?.tncurl ?? false

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    //#region Functions
    const handleChange = (e, value) => {
        setTab(value)
    }

    const enableEditProfile = () => {
        setEditMode("profile");
    }

    const handleEditProfile = () => {
        setEditMode(null);
    }
    const enableEditSub = () => {
        setEditMode("sub");
    }

    const handleEditSub = () => {
        setEditMode(null);
    }

    const cancelEdit = () => {
        setEditMode(null);
    }

    const handleTNCClick = () => {
        window.open(TNC_URL)
    }

    useEffect(() => {
        if (show) {
            setContainerClass(null);
            setDrawerContainerClass(null);
        } else {
            setContainerClass(classes.containeropen);
            setDrawerContainerClass(classes.drawercontaineropen);
        }
    }, [show]);

    useEffect(() => {
        const init = async () => {
            const noti = await fetchUnreadNotifications(location?.state?.userId)
            setNotifications(noti)
            const res = await getCustomerDetails(location?.state?.userId, state.token);
            setData(res)
            setLoading(false)
        }
        init();

    }, [])
    //#endregion

    const NotificationList = () => {
        return (
            // console.log("notifications ==>", notifications)
            <Box className={classes.notiContainer}>
                {notifications.length ?
                    notifications.sort(sortNoti).map(item => (
                        <Box key={item.messageId} >
                            <Box className={classes.notiBox}>
                                {item.sentTime && <Typography color={"GrayText"} sx={{ fontSize: 10 }} >{new Date(item.sentTime).toLocaleTimeString()}</Typography>}
                                <Typography >{item.notification.title}</Typography>
                                <Typography color={"GrayText"} sx={{ fontSize: 14 }} >{item.notification.body}</Typography>
                            </Box>
                            <Divider />
                        </Box>
                    ))
                    : <Typography color={"GrayText"} sx={{ fontSize: 14, m: 1 }}>No unread notifications</Typography>
                }
            </Box>
        )
    }

    return (
        <>
            <Stack direction="row">
                <Box
                    height="100%"
                    className={clsx(classes.drawercontainer, drawerContainerClass)}
                >
                    <Drawer show={show} location="customers" />
                </Box>

                <Box className={clsx(classes.container, containerClass)}>
                    <Stack direction={"row"} justifyContent={"space-between"} >
                        <BreadcrumbTrail sx={{ my: 2 }} />
                        <Stack direction={"row"} alignItems={"center"} >
                            <Typography fontSize={14} color={"GrayText"} >Customer Notifications</Typography>
                            <div>
                                <IconButton aria-describedby={id} sx={{ mr: 6 }} onClick={handleClick} >
                                    <Badge badgeContent={notifications.length} color="error">
                                        <Notifications sx={{ fontSize: 24 }} color="primary" />
                                    </Badge>
                                </IconButton>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <NotificationList />
                                </Popover>
                            </div>
                        </Stack>
                    </Stack>
                    <Box className={classes.main}>
                        {/* <Card sx={{ borderRadius: 5 }} > */}
                        <Box
                            className={classes.profileDetails}
                        >
                            <Container
                                focused={editMode === "profile"}
                                title="Profile"
                                sideComponent={<Button onClick={enableEditProfile} variant="text">Edit</Button>}
                                style={{
                                    root: { borderRadius: 10, marginBottom: 0, flex: 1, },
                                    child: { padding: 30 }
                                }}
                            >
                                <style>{`
                                    .profileInfoDiv .MuiInput-root::before {
                                            border-bottom: ${editMode === "profile" ? "1px solid rgba(0, 0, 0, 0.42)" : "none !important"}
                                        }
                                    .disabledDiv .MuiInput-root::before {
                                            border-bottom: ${editMode === "sub" ? "1px solid rgba(0, 0, 0, 0.42)" : "none !important"}
                                        }
                                `}</style>
                                <Stack spacing={3} width={"100%"} >
                                    <Typography fontWeight={600}>Profile information</Typography>
                                    <Grid className="profileInfoDiv" container spacing={3} >
                                        <Grid item xs={5}>
                                            {loading ?
                                                <Skeleton variant="circular" width={140} height={140} />
                                                : (
                                                    <Box className={classes.avatar}>
                                                        <NameAvatar
                                                            size={140}
                                                            name={`${data?.user?.firstName} ${data?.user?.secondName}`}
                                                        />
                                                        {/* <Box className={classes.avtEdit}>
                                                            <IconButton color="inherit" >
                                                                <ModeEdit fontSize="small" color="inherit" />
                                                            </IconButton>
                                                        </Box> */}
                                                    </Box>
                                                )}
                                        </Grid>
                                        <Grid item xs={7} paddingRight={"24px"}>
                                            <Stack spacing={2} >
                                                {loading ?
                                                    <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                    : (
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>First name</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode === "profile" ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={data?.user?.firstName}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    )}
                                                {loading ?
                                                    <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                    : (
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>Last name</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode === "profile" ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={data?.user?.secondName}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    )}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={5}>
                                            {loading ?
                                                <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                : (
                                                    <div>
                                                        <Typography fontSize={14} color={"GrayText"}>Phone number</Typography>
                                                        <TextField
                                                            sx={{ pointerEvents: editMode === "profile" ? "unset" : "none" }}
                                                            variant="standard"
                                                            className={classes.textfield}
                                                            size="small"
                                                            value={data?.user?.phoneNumber}
                                                            inputProps={textFieldProps}
                                                        />
                                                    </div>
                                                )}
                                        </Grid>

                                        <Grid item xs={7} paddingRight={"24px"}>
                                            {loading ?
                                                <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                : (
                                                    <div>
                                                        <Typography fontSize={14} color={"GrayText"}>Email</Typography>
                                                        <TextField
                                                            fullWidth
                                                            sx={{ pointerEvents: editMode === "profile" ? "unset" : "none" }}
                                                            variant="standard"
                                                            className={classes.textfield}
                                                            size="small"
                                                            value={data?.user?.emailId}
                                                            inputProps={textFieldProps}
                                                        />
                                                    </div>
                                                )}
                                        </Grid>

                                        <Grid item xs={12} />
                                        {editMode === "profile" &&
                                            <Stack direction={"row"} spacing={4} >
                                                <Button variant="contained" sx={{ backgroundColor: "gray" }} onClick={cancelEdit}>Cancel</Button>
                                                <Button variant="contained" type="submit" onClick={handleEditProfile}>Save changes</Button>
                                            </Stack>
                                        }

                                        <Grid item xs={12} />
                                        <Typography fontWeight={600}>Document links</Typography>
                                        <Grid item xs={12} padding={"5px !important"} className="disabledDiv" >
                                            <Stack spacing={2} direction={"column"} justifyContent={"flex-start"} >
                                                {loading ?
                                                    <>
                                                        <Skeleton variant="rectangular" height={35} width={200} />
                                                        <Skeleton variant="rectangular" height={35} width={200} />
                                                    </>
                                                    : (
                                                        <Button
                                                            disabled={!TNC_URL}
                                                            onClick={handleTNCClick}
                                                            sx={{ width: "max-content", paddingLeft: "0px !important" }}
                                                            endIcon={<Launch fontSize="small" />}
                                                        >
                                                            End-User License Agreement
                                                        </Button>
                                                    )}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Container>

                            <Container
                                focused={editMode === "sub"}
                                title="Subscription information"
                                sideComponent={<Button sx={{ opacity: 0 }} disabled onClick={enableEditSub} variant="text">Edit</Button>}
                                style={{
                                    root: { borderRadius: 10, marginBottom: 0, flex: 1, },
                                    child: { padding: 30 }
                                }}
                            >
                                <Box className="disabledDiv" style={{ height: "100%" }} >
                                    <Stack spacing={2} >
                                        <Typography fontWeight={600}>Active plan</Typography>
                                        <Stack direction={"row"} spacing={2}>
                                            {loading ?
                                                <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                : (
                                                    <div>
                                                        <Typography fontSize={14} color={"GrayText"}>Plan Name</Typography>
                                                        <TextField
                                                            sx={{ pointerEvents: "none" }}
                                                            variant="standard"
                                                            className={classes.textfield}
                                                            size="small"
                                                            value={data?.plan?.name ?? "-"}
                                                            inputProps={textFieldProps}
                                                        />
                                                    </div>
                                                )}
                                            {loading ?
                                                <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                : (
                                                    <div>
                                                        <Typography fontSize={14} color={"GrayText"}>Payment frequency</Typography>
                                                        <TextField
                                                            sx={{ pointerEvents: "none" }}
                                                            variant="standard"
                                                            className={classes.textfield}
                                                            size="small"
                                                            value={data?.plan?.frequency === 1 ? "Yearly" : data?.plan?.frequency === 0 ? "Monthly" : "-"}
                                                            inputProps={textFieldProps}
                                                        />
                                                    </div>
                                                )}
                                        </Stack>
                                        {loading ?
                                            <Skeleton variant="rectangular" height={60} width={"100%"} />
                                            : (
                                                <>
                                                    {data.subscription?.status !== "active" ?
                                                        <Typography>No active subscription</Typography>
                                                        : (
                                                            <Stack direction={"row"} spacing={0.5} >
                                                                <Typography fontSize={18}>£</Typography>
                                                                <Typography variant="h4" color={theme.palette.primary.main}>{data?.plan?.amount}</Typography>
                                                                <Typography fontSize={18} sx={{ alignSelf: "flex-end" }}>/{data?.plan?.frequency ? "Year" : "Month"}</Typography>
                                                            </Stack>
                                                        )}
                                                    {loading ?
                                                        <Skeleton variant="rectangular" height={60} width={"100%"} />
                                                        : (
                                                            <>
                                                                {data.subscription?.status === "active" &&
                                                                    <>
                                                                        {/* <Typography fontSize={22} fontWeight={600} > {data?.subscription?.planName} </Typography> */}
                                                                        < Stack direction={"row"} spacing={0.5} >
                                                                            <Typography color="GrayText">Next due date:</Typography>
                                                                            <Typography fontSize={18} fontWeight={600}>
                                                                                {moment(data?.subscription?.endDate).format("dddd, DD MMM, yyyy")}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                </>)}
                                        <Typography fontWeight={600}>Active card</Typography>
                                        {loading ?
                                            <Skeleton variant="rectangular" height={200} width={"100%"} />
                                            : (
                                                <CreditCard
                                                    name={`${data?.user?.firstName} ${data?.user?.secondName}`}
                                                    {...data?.card}
                                                />
                                            )}
                                        {editMode === "sub" &&
                                            <Stack direction={"row"} spacing={4} >
                                                <Button variant="contained" sx={{ backgroundColor: "gray" }} onClick={cancelEdit}>Cancel</Button>
                                                <Button variant="contained" type="submit" onClick={handleEditSub}>Save</Button>
                                            </Stack>
                                        }
                                    </Stack>

                                </Box>
                            </Container>
                        </Box>
                        <Container title="Devices and Invoices">
                            <Stack spacing={2}>
                                <Tabs value={tab} onChange={handleChange} >
                                    <Tab
                                        value={"devices"}
                                        label="Devices"
                                    />
                                    <Tab
                                        value={"invoices"}
                                        label="Invoices"
                                    />
                                </Tabs>
                                {
                                    tab === "devices" ?
                                        <DeviceList
                                            data={data?.devices}
                                            navigate={navigate}
                                            loading={loading}
                                        />
                                        : (
                                            <InvoiceList
                                                data={data.invoices}
                                                loading={loading}
                                            />
                                        )
                                }
                            </Stack>
                        </Container>
                    </Box>
                </Box >
            </Stack >
        </>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 60,
        width: "80%",
        transition: "width 0.3s ease-out",
        padding: 0
    },
    main: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        margin: 40,
        marginTop: 0
    },
    containeropen: {
        width: "100%",
    },
    drawercontainer: {
        width: "20%",
        transition: "width 0.1s ease-out",
    },
    drawercontaineropen: {
        width: "0%",
        zIndex: "100",
    },
    profileDetails: {
        display: "flex",
        gap: 20,
        flexDirection: "row",
        justifyContent: "space-evenly",
        // backgroundColor: "#d8f6ff"
        // backgroundImage: `linear-gradient(60deg, #d8f6ff, #9e9e9e)`,
        // backgroundImage: `linear-gradient(#9e9e9e, #d8f6ff, #9e9e9e)`
        // backgroundImage: `linear-gradient(#97b9bf, #d8f6ff, #97b9bf)`

    },
    notiContainer: {
        maxHeight: "60vh",
        overflowY: "auto"
    },
    notiBox: {
        padding: 10,
    },
    textfield: {
        // backgroundColor: "#fff",
        width: "max-content"
    },
    avatar: {
        width: "max-content",
        position: "relative"
    },
    avtEdit: {
        position: "absolute",
        top: 0,
        right: 5,
        backgroundColor: "gray",
        borderRadius: "50%",
        color: "#fff"
    }
}))